<template>
  <v-container>
    <h1 class="title title-md">
      Добро пожаловать
    </h1>
    <div class="main-form">
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        @submit.prevent="login"
      >
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-if="!isPhone"
              v-model="data.email"
              :rules="rules.email"
              key="name"
              label="Почта"
              required
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="data.password"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.password.required, rules.password.min]"
              :type="showPassword ? 'text' : 'password'"
              label="Пароль"
              @click:append="showPassword = !showPassword"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-btn large color="primary" class="btn" block type="submit">Войти</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </v-container>
</template>

<script>

import {mapActions, mapGetters, mapMutations} from "vuex";
import axios from "axios";

export default {
  name: 'home',
  data() {
    return {
      valid: true,
      isPhone: false,
      showPassword: false,
      data: {
        email: '',
        password: '',
      },
      rules: {
        email: [
          v => !!v || 'E-mail обязателен',
          v => /.+@.+\..+/.test(v) || 'E-mail заполнен неверно',
        ],
        password: {
          required: value => !!value || 'Пароль обязателен',
          min: v => v.length >= 2 || 'Пароль должен быть длиннее 3 символов',
        },
      }
    }
  },
  methods: {

    async login() {
      this.$refs.form.validate();
      if (!this.$refs.form.validate()) {
        return
      }
      this.setIsLoading(true)
      try {
        const response = await axios({
          baseURL: this.URL + '/api/t/authentication_token',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          method: "POST",
          data: JSON.stringify(this.data)
        })
        const token = response.data.token;
        this.decodeJwt(token)
      } catch (e) {
        this.showAlert({
          show: true,
          type: 'error',
          msg: e.response.data.message
        })
      } finally {
        this.setIsLoading(false)
      }

    },
    ...mapMutations(['showAlert']),
    ...mapActions(['setIsLoading', 'decodeJwt'])
  },
  computed: {
    ...mapGetters(['URL', 'alert'])
  },
}
</script>

<style lang="scss" scoped>

.main-form {
  max-width: 640px;
  margin: 0 auto;
}

.login-error {
  position: fixed;
  top: 32px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3333;
}
</style>
