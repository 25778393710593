import Vue from "vue";
import VueRouter from "vue-router";
import Landing from "@/views/Landing";
import ProfilePage from "@/views/ProfilePage";
import store from "@/store";
import LessonsPage from "@/views/LessonsPage";
import Test from "@/views/test.vue";
import AboutProfilePage from "@/views/AboutProfilePage.vue";
import VideoCall from "@/views/VideoCall.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Landing,
  },
  {
    path: "/profile",
    name: "profile",
    component: ProfilePage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/about",
    name: "about",
    component: AboutProfilePage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/lessons",
    name: "lessons",
    component: LessonsPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/call",
    name: "videocall",
    component: VideoCall,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/test",
    name: "test",
    component: Test,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  const dateNow = Date.now();
  const tokenExp = store.state.tokenExp;
  const auth = store.state.isAuth;

  if (tokenExp && dateNow >= tokenExp) {
    store.dispatch("logout").then((r) =>
      store.commit("showAlert", {
        show: true,
        type: "error",
        msg: "Время сессии истекло <br> Авторизуйтесь для продолжения",
      })
    );
    next({ name: "home" });
  }
  if (to.name === "home" && auth) {
    next({ name: "profile" });
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.getters.isAuth) {
      next({ name: "home" });
      store.commit("showAlert", {
        show: true,
        type: "error",
        msg: "Время сессии истекло <br> Авторизуйтесь для продолжения",
      });
    } else {
      store.dispatch("fetchUserInfo").then((r) => r);
      next();
    }
  } else {
    next(); // does not require auth, make sure to always call next()!
  }
  //  обработка call
  if (to.name === "videocall") {
    if (!store.state.lessonData) {
      router.push({ name: "profile" }).then((r) =>
        store.commit("showAlert", {
          show: true,
          type: "error",
          msg: "Для доступа к уроку выберите его из таблицы",
        })
      );
    } else {
      next();
    }
  }
});
export default router;
