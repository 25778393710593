<script setup>
import StreamPlayer from "@/components/StreamPlayer.vue";
import CanvasDesk from "@/components/CanvasDesk.vue";
import { Peer } from "peerjs";
import { useStore } from "@/store";
import axios from "axios";
import { onBeforeUnmount, onMounted, ref } from "vue";
import { useRouter } from "vue-router/composables";

const store = useStore();
const callData = ref(null);
const peer = ref(null);
const connection = ref(null);
const peercall = ref(null);
const otherStream = ref(null);
const myStream = ref(null);
const isCallStart = ref(false);
const shareCanvas = ref(false);
const router = useRouter();
const JSONdata = ref({});

const getCallData = async () => {
  const URL = `${store.getters.URL}/api/t/lesson/${store.getters.lessonData.id}/start`;
  try {
    const response = await axios({
      baseURL: URL,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${store.getters.token}`,
        "Content-Type": "application/merge-patch+json",
      },
      method: "PATCH",
      data: {},
    });
    callData.value = response.data;
  } catch (e) {
    store.commit("showAlert", {
      show: true,
      type: "error",
      msg: e.message,
    });
    throw new Error(e);
  }
};
const peerInit = () => {
  // инит звонка
  peer.value = new Peer(callData.value.peerIds.teacher, {
    config: callData.value.teacherAuth,
    sdpSemantics: "unified-plan",
  });
  peer.value.on("open", (id) => {
    console.log("My peer ID is: " + id);
  });
  peer.value.on("error", (error) => {
    console.error(error);
  });
  // входящие
  peer.value.on("connection", (conn) => {
    connection.value = conn;
    connection.value.on("data", (data) => {
      console.log(`received: ${data}`);
      JSONdata.value = JSON.parse(data);
    });
    connection.value.on("open", () => {
      // connection.value.send("hello!");
    });
  });
  // ответ на звонок
  peer.value.on("call", (call) => {
    peercall.value = call;
    acceptCall();
  });
};
const acceptCall = (call) => {
  navigator.mediaDevices
    .getUserMedia({
      audio: true,
      video: {
        width: { min: 320, ideal: 1100, max: 1200 },
        height: { min: 220, ideal: 615, max: 615 },
        facingMode: "user",
      },
    })
    .then((mediaStream) => {
      //Наш поток отправим ученику
      peercall.value.answer(mediaStream);
      peercall.value.on("stream", (stream) => {
        //нам ответили, получим стрим
        setTimeout(() => {
          otherStream.value = stream;
        }, 1500);
      });
      myStream.value = mediaStream;
    })
    .catch((err) => {
      store.commit("showAlert", {
        show: true,
        type: "error",
        msg: "Доступ к камере запрещен. Проверьте настройки браузера/системы",
      });
      throw new Error(err);
    });
  isCallStart.value = true;
};

const toggleVideoStream = async (arg) => {
  if (arg === "rm") {
    try {
      navigator.mediaDevices
        .getUserMedia({ audio: true, video: false })
        .then(function (stream) {
          myStream.value = stream;
          peercall.value.peerConnection
            .getSenders()[1]
            .replaceTrack(stream.getVideoTracks()[0]);
        });
    } catch (e) {
      throw new Error(e);
    }
  } else {
    try {
      navigator.mediaDevices
        .getUserMedia({ audio: true, video: true })
        .then(function (stream) {
          myStream.value = stream;
          peercall.value.peerConnection
            .getSenders()[1]
            .replaceTrack(stream.getVideoTracks()[0]);
        });
    } catch (e) {
      throw new Error(e);
    }
  }
};
const toggleMicroStream = async (arg) => {
  if (arg === "rm") {
    try {
      navigator.mediaDevices
        .getUserMedia({ audio: false, video: true })
        .then(function (stream) {
          myStream.value = stream;
          peercall.value.peerConnection
            .getSenders()[0]
            .replaceTrack(stream.getAudioTracks()[0]);
        });
    } catch (e) {
      throw new Error(e);
    }
  } else {
    try {
      navigator.mediaDevices
        .getUserMedia({ audio: true, video: true })
        .then(function (stream) {
          myStream.value = stream;
          // peercall.peerConnection.getSenders()[1].replaceTrack(stream)
          peercall.value.peerConnection
            .getSenders()[0]
            .replaceTrack(stream.getAudioTracks()[0]);
        });
    } catch (e) {
      throw new Error(e);
    }
  }
};
const shareScreen = () => {
  const constraints = {
    video: true,
    audio: {
      echoCancellation: true,
      noiseSuppression: true,
      sampleRate: 44100,
    },
  };

  navigator.mediaDevices
    .getDisplayMedia(constraints)
    .then(async function (stream) {
      // Получение потока видео и аудио с микрофона
      const micStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      // Создание AudioContext
      const audioContext = new AudioContext();
      // Создание MediaStreamAudioSourceNode для потока аудио с микрофона
      const micSourceNode = audioContext.createMediaStreamSource(micStream);
      // Создание MediaStreamAudioSourceNode для потока аудио с дисплея
      const displaySourceNode = audioContext.createMediaStreamSource(stream);
      // Создание MediaStreamAudioDestinationNode для смешивания аудиодорожек
      const destinationNode = audioContext.createMediaStreamDestination();
      // Подключение источников к узлу смешивания
      micSourceNode.connect(destinationNode);
      displaySourceNode.connect(destinationNode);
      // Получение смешанного потока
      const mixedStream = destinationNode.stream;
      // Обработка полученного потока

      myStream.value = new MediaStream([
        stream.getVideoTracks()[0],
        mixedStream.getAudioTracks()[0],
      ]);
      await peercall.value.peerConnection
        .getSenders()[1]
        .replaceTrack(stream.getVideoTracks()[0]);
      await peercall.value.peerConnection
        .getSenders()[0]
        .replaceTrack(mixedStream.getAudioTracks()[0]);
    })
    .catch((error) => {
      console.error("Error accessing screen:", error);
    });
};
const toggleCanvas = () => {
  shareCanvas.value
    ? connection.value.send("canvasEnd")
    : connection.value.send("canvasGo");
  shareCanvas.value = !shareCanvas.value;
};
const endCall = async () => {
  const URL = `${store.getters.URL}/api/t/lesson/${store.getters.lessonData.id}/finish`;
  try {
    const response = await axios({
      baseURL: URL,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${store.getters.token}`,
        "Content-Type": "application/merge-patch+json",
      },
      method: "PATCH",
      data: {},
    });
    store.commit("showAlert", {
      show: true,
      type: "info",
      msg: "Занятие завершено, спасибо за урок!",
    });
    setTimeout(() => {
      router.push({ name: "profile" });
    }, 5000);
    connection.value.send("endLesson");
  } catch (e) {
    store.commit("showAlert", {
      show: true,
      type: "error",
      msg: e.message,
    });
    throw new Error(e);
  }
};
const JSONsend = (json) => {
  connection.value.send(json);
};
const toggleMute = (bool) => {
  if (bool) {
    connection.value.send("canvasMute");
  } else {
    connection.value.send("canvasUnmute");
  }
};
const sendImg = (data) => {
  connection.value.send(JSON.stringify(data))
};
onMounted(async () => {
  await getCallData();
  peerInit();
});
onBeforeUnmount((n) => {
  router.go(n);
});
</script>

<template>
  <div class="call pb-10">
    <v-container>
      <h1 class="title-md mt-0">Видео-урок</h1>
      <v-row>
        <v-col cols="12">
          <v-alert type="success" :icon="false">
            <div class="d-flex align-center">
              <span>Ваш ученик:</span>
              <p class="font-weight-500 mb-0 ml-4">
                {{ store.getters.lessonData.user.firstName }}
              </p>
            </div>
          </v-alert>
        </v-col>
      </v-row>
      <div v-if="isCallStart" class="stream">
        <StreamPlayer
          :myStream="myStream"
          :otherStream="otherStream"
          :absolute="shareCanvas"
          @toggleVideo="toggleVideoStream"
          @toggleMicro="toggleMicroStream"
          @shareScreen="shareScreen"
          @endCall="endCall"
          @toggleCanvas="toggleCanvas"
        />
        <CanvasDesk
          v-if="shareCanvas"
          :JSONcanvas="JSONdata"
          @JSON="JSONsend"
          @toggleMute="toggleMute"
          @sendImg="sendImg"
        />
      </div>
      <v-row v-else>
        <v-col cols="12">
          <div class="videobg">
            <div class="videobg__content">
              <img src="@/assets/images/loading.svg" alt="" />
              <span>Дождитесь звонка от ученика</span>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style scoped lang="scss">
.title-md {
  font-size: 32px !important;
  font-family: "Nunito", sans-serif !important;
  text-align: center;
}

.videobg {
  width: 1100px;
  height: 615px;
  background: url("@/assets/images/videobg.png") center / cover no-repeat;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 32px;
    height: 32px;
  }

  span {
    color: #727272;
    line-height: 150%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    background: #fff;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0 8px 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding: 40px 50px;
  }
}

.stream {
  position: relative;
  width: 100%;
  min-height: 615px;
  margin: 0 auto;
}
</style>
