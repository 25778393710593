<template>
  <CanvasDesk/>
</template>

<script>
import CanvasDesk from "@/components/CanvasDesk.vue";

export default {
  name: "test",
  components: {CanvasDesk}
}
</script>

<style scoped>

</style>
