<template>
  <v-app-bar
    fixed
    color="white"
    elevate-on-scroll
    hide-on-scroll
    class="header"
    :height="104"
    app
  >

    <v-container class="header__container">
      <v-toolbar-title>
        <div class="header__logo">
          <router-link to="/">
            <img src="@/assets/svg/header-logo.svg" alt="Логотип">
          </router-link>
        </div>
      </v-toolbar-title>
    </v-container>
  </v-app-bar>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "LandingHeader",
  data() {
    return {}
  },
  methods: {},
  computed: {},

}
</script>

<style scoped>

</style>
