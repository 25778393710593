<template>
  <div class="popup">
    <div class="popup__header">
      <div class="popup__title">Фильтр</div>
      <v-btn @click="reset" outlined small color="gray" rounded>Сборосить фильтр</v-btn>
      <v-btn @click="$emit('close')" icon class="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
    <div class="popup__body">
      <v-form @submit.prevent="submit" ref="form">
        <v-checkbox
          v-for="cbx in checkboxes"
          :key="cbx.label"
          v-model="filters.type"
          :label="cbx.label"
          :value="cbx.value"
          hide-details
        />

        <v-text-field
          label="Имя ученика"
          class="my-4"
          hide-details
          v-model.trim="filters.name"
        />
        <v-row>
          <v-col cols="6">
            <v-text-field
              placeholder="01.01.2023"
              v-maska="'##.##.####'"
              class="mt-4"
              :rules="rules.date"
              hint="Заполните поле до коцна, либо оставьте его пустым"
              v-model.trim="filters.dateStart"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              placeholder="01.07.2023"
              v-maska="'##.##.####'"
              class="mt-4"
              :rules="rules.date"
              hint="Заполните поле до коцна, либо оставьте его пустым"
              v-model.trim="filters.dateFinish"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-btn type="submit" color="primary">Применить</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </div>
</template>

<script>
export default {
  name: "TeacherFilters",
  data() {
    return {
      valid: true,
      filters: {
        type: [],
        name: '',
        dateStart: '',
        dateFinish: '',
      },
      checkboxes: [
        {
          label: '«Стандарт», 25 мин.',
          value: 'standard 25'
        },
        {
          label: '«Стандарт», 55 мин.',
          value: 'standard 55'
        },
        {
          label: '«Премиум», 25 мин.',
          value: 'premium 25'
        },
        {
          label: '«Премиум», 55 мин.',
          value: 'premium 55'
        },
      ],
      rules: {
        date: [
          v => v.length === 10 || v.length === 0
        ]
      },
    }
  },
  methods: {
    submit() {
      this.$refs.form.validate();
      if (!this.$refs.form.validate()) {
        return
      }
      this.$emit('filter', this.filters)
      this.$emit('close')
    },
    reset() {
      this.filters = {
        type: [],
        name: '',
        dateStart: '',
        dateFinish: '',
      }

    },

  }
}
</script>

<style lang="scss" scoped>
.popup {
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 20px;
  position: relative;
  padding: 50px 80px;
  // .popup__header
  &__header {
    margin: 0 0 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    font-weight: 900;
    font-size: 32px;
    line-height: 120%;
    font-family: 'Nunito', serif;
  }
}

.close {
  position: absolute;
  top: 12px;
  right: 12px;
}
</style>
