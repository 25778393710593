<template>
  <v-app>
    <v-main>
      <landing-header v-if="!isAuth"/>
      <profile-header v-if="isAuth"/>
      <div class="page">
        <router-view/>
      </div>
      <landing-footer/>
      <loader v-if="isLoading"/>

      <transition name="fade">
        <v-alert v-if="alert.show" class="global-alert" :type="alert.type" v-html="alert.msg"></v-alert>
      </transition>
      <transition name="fade">
        <v-snackbar
          v-model="checkMedia"
          color="primary"
          right
        >
          Проверяю доступность камеры и микрофона
        </v-snackbar>

      </transition>
    </v-main>
  </v-app>
</template>

<script>

import Loader from "@/components/Loader";
import {mapGetters, mapActions} from "vuex/dist/vuex.mjs";
import LandingHeader from "@/components/mainPage/LandingHeader";
import LandingFooter from "@/components/mainPage/LandingFooter";
import ProfileHeader from "@/components/profilePage/ProfileHeader";
import axios from "axios";

export default {
  name: 'App',
  components: {ProfileHeader, LandingFooter, LandingHeader, Loader},
  data() {
    return {
      activeTabLogin: 1,
    }
  },
  methods: {
    ...mapActions(['setIsLoading', 'setIsAuth']),
  },
  computed: {
    ...mapGetters(['isLoading', 'isAuth', 'token', 'alert', 'checkMedia'])
  },
  async mounted() {

  },
  beforeDestroy() {
  },
};
</script>
