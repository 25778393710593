<template>
  <div class="content">
    <v-container>
      <div class="card">
        <div class="card__header">
          <v-row>
            <v-col cols="12">
              <div class="card__title">Информация обо мне</div>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
        </div>
        <div class="card__body">
          <div class="photo">
            <img v-if="userInfo.photo" :src="URL+userInfo.photo"/>
            <img v-else src="@/assets/images/user.jpg"/>
          </div>
          <div class="form">
            <v-form @submit.prevent>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Ваше имя"
                    v-model="userInfo.fullName"
                    disabled
                    outlined
                    hide-details
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Опыт работы"
                    v-model="userInfo.experience"
                    disabled
                    outlined
                    hide-details
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Специализация"
                    v-model="userInfo.spec"
                    disabled
                    outlined
                    hide-details
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Языки"
                    v-model="userInfo.langs"
                    disabled
                    outlined
                    hide-details
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="E-mail"
                    v-model="userInfo.email"
                    disabled
                    outlined
                    hide-details
                  />
                </v-col>
              </v-row>
            </v-form>
            <div class="description">
              Премиум-аккаунт: <span>{{ userInfo.premium ? 'Да' : 'Нет' }}</span>
            </div>
          </div>
        </div>
      </div>

    </v-container>
  </div>
</template>

<script>
import {mapGetters} from "vuex";


export default {
  name: "ProfilePage",
  data() {
    return {}
  },
  methods: {},
  computed: {
    ...mapGetters(['userInfo', 'URL']),
  },

  async mounted() {
  },

}
</script>

<style lang="scss" scoped>
.content {
  background: #F7F7F7;
  padding: 50px 40px;
  height: 100%;
}

.card {
  padding: 32px 32px;
  background: #fff;
  border: 1px solid #f2f2f2;
  border-radius: 15px;
  // .card__header
  &__header {
    padding: 0 0 32px;
  }

  // .card__title
  &__title {
    font-family: 'Nunito', serif;
    font-weight: 900;
    font-size: 32px;
    text-align: center;
  }

  &__body {
    display: flex;
    justify-content: center;
    gap: 80px;
  }
}

.photo {
  width: 176px;
  height: 176px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  flex: 0 0 176px;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
  }
}

.form {
  flex: 0 0 500px;
}

.description {
  margin: 32px 0 0;
  font-size: 18px;
  color: #9C9C9C;
  display: flex;
  align-items: center;
  gap: 12px;

  span {
    color: #4C4C4C;
  }
}
</style>
