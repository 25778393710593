<template>
  <div class="videostream">
    <div ref="video" :class="['video mb-8', {absolute: absolute}]">
      <div v-show="otherStream" :class="['im', {full: fullscreen}]">
        <video ref="myVideo" no-cotrols></video>
        <div class="video-controls">
          <div
            @click="toggleMicro"
            class="control micro"
          >
            <v-icon color="#fff">
              {{ mutedMicro ? 'mdi-microphone-off' : 'mdi-microphone' }}
            </v-icon>
          </div>
          <div
            @click="toggleMute"
            class="control volume"
          >
            <v-icon color="#fff">
              {{ muted ? 'mdi-volume-off' : 'mdi-volume-high' }}
            </v-icon>
          </div>
          <div
            @click="toggleVideo"
            class="control videobtn"
          >
            <v-icon color="#fff">
              {{ hiddenVideo ? 'mdi-video-off-outline' : 'mdi-video-outline' }}
            </v-icon>
          </div>
          <div
            @click="shareScreen"
            class="control scr"
          >
            <v-icon color="#fff">
              {{ hiddenVideo ? 'mdi-monitor-speaker' : 'mdi-monitor-speaker-off' }}
            </v-icon>
          </div>
          <div
            @click="toggleFullscreen"
            class="control fs"
          >
            <v-icon color="#fff">
              {{ fullscreen ? 'mdi-fullscreen-exit' : 'mdi-fullscreen' }}
            </v-icon>
          </div>
          <div
              @click="toggleDesk"
              class="control"
          >
            <v-icon color="#fff">
              mdi-view-dashboard
            </v-icon>
          </div>
          <div
            @click="$emit('endCall')"
            class="end"
          >
            <v-icon color="#fff">
              mdi-phone-hangup
            </v-icon>
          </div>
        </div>
      </div>
      <div v-show="myStream" class="another">
        <video ref="anotherVideo" muted no-cotrols></video>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "StreamPlayer",
  props: {
    myStream: {
      required: true,
    },
    otherStream: {
      required: true,
    },
    absolute: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      myVideo: null,
      muted: false,
      hiddenVideo: false,
      mutedMicro: false,
      fullscreen: false,
      newMyStream: null,
      newOtherStream: null,
    }
  },
  mounted() {
    this.myVideo = this.$refs.myVideo;
  },
  methods: {
    toggleDesk() {
      this.$emit('toggleCanvas')
    },
    shareScreen() {
      this.hiddenVideo = true
      this.mutedMicro = true
      this.$emit('shareScreen')
    },
    shareVideo() {
      this.$emit('shareVideo')
    },
    toggleMute() {
      this.muted = !this.muted
      this.myVideo.muted = !this.myVideo.muted
    },
    toggleMicro() {
      this.mutedMicro = !this.mutedMicro
      if (this.mutedMicro) {
        this.$emit('toggleMicro', 'rm')
      } else {
        this.$emit('toggleMicro', 'add')
      }
    },
    toggleVideo() {
      this.hiddenVideo = !this.hiddenVideo
      if (this.hiddenVideo) {
        this.$emit('toggleVideo', 'rm')
      } else {
        this.$emit('toggleVideo', 'add')
      }
    },
    toggleFullscreen() {
      if (!this.fullscreen) {
        if (this.$refs.video.requestFullScreen) {
          this.$refs.video.requestFullScreen();
        } else if (this.$refs.video.webkitRequestFullScreen) {
          this.$refs.video.webkitRequestFullScreen();
        } else if (this.$refs.video.mozRequestFullScreen) {
          this.$refs.video.mozRequestFullScreen();
        }
        this.fullscreen = true;
      } else {
        this.fullscreen = false;
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }
      }
    },
    async attachMyVideo(stream) {
      const video = this.$refs.anotherVideo;
      this.newMyStream = new MediaStream(stream.getTracks())
      video.srcObject = this.newMyStream;
      video.onloadedmetadata = function (e) {
        video.play();
      };
    },
    async attachOtherVideo(stream) {
      const video = this.$refs.myVideo;
      this.newOtherStream = new MediaStream(stream.getTracks())
      video.srcObject = this.newOtherStream;
      video.onloadedmetadata = function (e) {
        console.log('loaded')
        video.play();
      };
    }
  },
  watch: {
    async myStream() {
      if (this.myStream) {
        await this.attachMyVideo(this.myStream)
      }
    },
    async otherStream() {
      if (this.otherStream) {
        await this.attachOtherVideo(this.otherStream)
      }
    },
  }

}
</script>

<style lang="scss" scoped>
.video {
  position: relative;
  width: 1100px;
  height: 615px;
  margin: 0 auto;
  transition: width 1s ease;

  video {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 8px;

    &::-webkit-media-controls {
      display: none !important;
    }
  }
}

.im {
  width: 1103px;
  height: 615px;
  position: relative;
  margin: 0 auto;
  transition: width 1s ease;

  &.full {
    width: 100%;
    height: 100%;
  }

  video {

  }
}

.another {
  width: 277px;
  height: 154px;
  position: absolute;
  bottom: 18px;
  right: 18px;
  z-index: 3;
  transition: width 1s ease;
}

.video-controls {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  z-index: 30;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  transition: width 1s;
}

.control {
  background: #535353;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.2);
  width: 42px;
  height: 42px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background: #444444;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  }
}

.end {
  background: #FF4343;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.2);
  border-radius: 28px;
  height: 42px;
  width: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background: #ff1818;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  }
}

.video.absolute {
  width: 150px;
  position: absolute;
  top: 20px;
  right: 0;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  gap: 5px;

  .im {
    width: 100%;
    height: 84px;
  }

  .another {
    width: 100%;
    height: 84px;
    position: relative;
    top: 0;
    left: 0;
  }

  .video-controls {
    flex-wrap: wrap;
    left: 0;
    top: calc(100% + 12px);
    transform: translateX(0);
  }
}
@media (max-width: 1500px) {
  .video.absolute {
    flex-direction: row;
    left: 0;
    margin: 0;
    width: 100%;

    .im,
    .another {
      height: 140px;
    }

    .video-controls {
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
    }
  }
}
</style>
