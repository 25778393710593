import Vue from "vue";
import Vuex from "vuex";
import router from "@/router";
import axios from "axios";
import { format, fromUnixTime, getUnixTime } from "date-fns";
import { ru } from "date-fns/locale";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    isLoading: false,
    isAuth: localStorage.getItem("auth") === "true",
    profileTab: 0,
    URL: 'https://p.uni-kid.ru',
    // URL: "https://unikid-api.itlabs.top",
    token: localStorage.getItem("token") ? localStorage.getItem("token") : null,
    tokenExp: localStorage.getItem("tokenExp")
      ? localStorage.getItem("tokenExp")
      : 0,
    alert: {
      show: false,
      type: "info",
      msg: "",
    },
    finishedLessons: [],
    upcomingLessons: [],
    lessonData: null,
    userInfo: [],
    checkMedia: false,
  },
  getters: {
    isLoading: (state) => state.isLoading,
    isAuth: (state) => state.isAuth,
    profileTab: (state) => state.profileTab,
    URL: (state) => state.URL,
    token: (state) => state.token,
    alert: (state) => state.alert,
    finishedLessons: (state) => state.finishedLessons,
    upcomingLessons: (state) => state.upcomingLessons,
    lessonData: (state) => state.lessonData,
    userInfo: (state) => state.userInfo,
    checkMedia: (state) => state.checkMedia,
  },
  mutations: {
    TOGGLE_LOADING(state, boolean) {
      state.isLoading = boolean;
    },
    TOGGLE_AUTH(state, boolean) {
      localStorage.setItem("auth", boolean);
      state.isAuth = boolean;
      if (!boolean) {
        router.push({ name: "home" }).then((r) => r);
        state.tokenExp = 0;
        localStorage.clear();
      } else {
        router.push({ name: "profile" }).then((r) => r);
      }
    },
    SET_PROFILE_TAB(state, number) {
      state.profileTab = number;
    },
    FETCH_TOKEN(state, token) {
      localStorage.setItem("token", token);
      state.token = token;
    },
    showAlert(state, alert) {
      state.alert = alert;
      setTimeout(() => {
        state.alert.show ? (state.alert.show = false) : "";
      }, 8000);
    },
    getTokenExp(state, { exp }) {
      state.tokenExp = exp * 1000;
      localStorage.setItem("tokenExp", String(exp * 1000));
    },
    createFinishedLessons(state, lessons) {
      state.finishedLessons = lessons;
    },
    createUpcomingLessons(state, lessons) {
      state.upcomingLessons = lessons;
    },
    createLessonData(state, data) {
      state.lessonData = data;
    },
    createUserInfo(state, data) {
      state.userInfo = data;
    },
  },
  actions: {
    async checkMediaStream({ commit, state }) {
      state.checkMedia = true;
      try {
        if (
          "mediaDevices" in navigator &&
          "getUserMedia" in navigator.mediaDevices
        ) {
        } else {
          commit("showAlert", {
            show: true,
            type: "error",
            msg: "Ваш браузер не поддерживает видео-звонки. <br> Воспользуйтесь другим, например - Chrome",
          });
        }
        const available = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: true,
        });
        available.getTracks()[0].stop();
        available.getTracks()[1].stop();
      } catch (e) {
        commit("showAlert", {
          show: true,
          type: "error",
          msg: "Доступ к камере и/или микрофону заблокирован.<br> Для работы видео-звонка разрешите доступ в настройках",
        });
      } finally {
        state.checkMedia = false;
      }
    },
    setIsLoading({ state, commit }, payload) {
      commit("TOGGLE_LOADING", payload);
    },
    async setIsAuth({ state, commit, dispatch }, payload) {
      commit("TOGGLE_AUTH", payload);
      // if (payload) {
      //   await dispatch("checkMediaStream");
      // }
    },
    setProfileTab({ state, commit }, payload) {
      commit("SET_PROFILE_TAB", payload.number);
    },

    decodeJwt({ state, commit, dispatch }, token) {
      // расшивроквка токена, запись даты окончания в локалтсорейдж и переменную
      let base64Url = token.split(".")[1];
      let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      let jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      commit("getTokenExp", JSON.parse(jsonPayload));
      commit("FETCH_TOKEN", token);
      dispatch("setIsAuth", true);
    },
    logout({ state, commit, dispatch }) {
      dispatch("setIsAuth", false);
    },
    async fetchLessons({ state, commit, dispatch }) {
      dispatch("setIsLoading", true);
      try {
        const response = await axios({
          baseURL: state.URL + "/api/t/lessons",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${state.token}`,
          },
          method: "GET",
        });
        const mapped = response.data.map(function (lesson) {
          const date = format(
            fromUnixTime(getUnixTime(new Date(lesson.dateTimeAt))),
            "d.MM.Y",
            { locale: ru }
          );
          const time = format(
            fromUnixTime(getUnixTime(new Date(lesson.dateTimeAt))),
            "H:mm",
            { locale: ru }
          );
          const unixTime = getUnixTime(new Date(lesson.dateTimeAt));

          return {
            date: date,
            time: time,
            unixTime: unixTime,
            typeDuration: `${lesson.type} ${lesson.duration}`,
            ...lesson,
          };
        });
        dispatch("sortingLessons", mapped);
      } catch (e) {
        commit("showAlert", {
          show: true,
          type: "error",
          msg: e.response.data.message,
        });
      } finally {
        dispatch("setIsLoading", false);
      }
    },
    sortingLessons({ commit }, lessons) {
      const finished = lessons.filter((lesson) => lesson.dateTimeFinishedAt);
      const upcoming = lessons.filter((lesson) => !lesson.dateTimeFinishedAt);
      commit("createFinishedLessons", finished);
      commit("createUpcomingLessons", upcoming);
    },
    async fetchUserInfo({ state, commit }) {
      try {
        const response = await axios({
          baseURL: state.URL + "/api/t/info",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${state.token}`,
          },
          method: "GET",
        });
        const spec = Object.values(response.data.specialization).join(", ");
        const langs = Object.values(response.data.speak).join(", ");
        const userInfo = {
          spec: spec,
          langs: langs,
          ...response.data,
        };
        commit("createUserInfo", userInfo);
      } catch (e) {
        commit("showAlert", {
          show: true,
          type: "error",
          msg: e.response.data.message,
        });
      }
    },
  },
  modules: {},
});
export default store;
export const useStore = () => store;
