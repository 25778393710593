<template>
  <v-container>
    <div class="canvas-wrapper">
      <div id="canvas" class="canvas" ref="canvas"></div>
      <!--      <div class="canvas-actions">-->
      <!--        <span>Инструмент</span>-->
      <!--        <div class="actions">-->
      <!--          <div class="actions__tabs">-->
      <!--            <div-->
      <!--              v-for="tab in actions"-->
      <!--              :key="tab.id"-->
      <!--              @click="activeTab = tab.id"-->
      <!--              :class="['actions__tab', { active: tab.id === activeTab }]"-->
      <!--            >-->
      <!--              <img :src="tab.img" :alt="tab.type" />-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <div class="actions__content">-->
      <!--            <div v-if="activeTab === 1 || activeTab === 2" class="action">-->
      <!--              <span>Размер</span>-->
      <!--              <div @click="brushSize&#45;&#45;" class="size-minus">-</div>-->
      <!--              <input-->
      <!--                class="size"-->
      <!--                type="number"-->
      <!--                ref="size"-->
      <!--                v-model="brushSize"-->
      <!--                disabled-->
      <!--              />-->
      <!--              <div @click="brushSize++" class="size-plus">+</div>-->
      <!--            </div>-->
      <!--            <div v-show="activeTab === 3" class="action">-->
      <!--              <textarea-->
      <!--                placeholder="Введите текст"-->
      <!--                v-model="textValue"-->
      <!--                ref="text"-->
      <!--              />-->
      <!--              <v-btn block color="primary" ref="textAdd">-->
      <!--                Добавить текст-->
      <!--              </v-btn>-->
      <!--            </div>-->
      <!--            <div v-if="activeTab === 1 || activeTab === 3" class="action">-->
      <!--              <span>Цвет</span>-->
      <!--              <input v-model="brushColor" class="color" type="color" />-->
      <!--            </div>-->
      <!--            <div v-show="activeTab === 4 || activeTab === 5" class="action">-->
      <!--              <div class="action-item">-->
      <!--                Обводка-->
      <!--                <input type="color" ref="strokeColor" />-->
      <!--              </div>-->
      <!--              <div class="action-item">-->
      <!--                Заливка-->
      <!--                <input type="color" ref="fillColor" />-->
      <!--              </div>-->
      <!--              <div class="action-item">-->
      <!--                <label>-->
      <!--                  <input type="checkbox" checked ref="fillTransparent" />-->
      <!--                  Прозрачная заливка-->
      <!--                </label>-->
      <!--              </div>-->
      <!--              <v-btn block color="primary" ref="rect"> Добавить</v-btn>-->
      <!--            </div>-->
      <!--            <div v-show="activeTab === 6" class="action">-->
      <!--              <v-btn v-show="!hasImage" block color="primary" ref="addImage">-->
      <!--                Вставить картинку-->
      <!--              </v-btn>-->
      <!--              <input class="file-input" type="file" ref="image" />-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <div class="actions__btn">-->
      <!--            <div class="action">-->
      <!--              <v-btn block color="primary" ref="clear"> Очистить поле</v-btn>-->
      <!--            </div>-->
      <!--            &lt;!&ndash;            <div class="action">&ndash;&gt;-->
      <!--            &lt;!&ndash;              <v-btn @click="toggleMute" block color="primary">&ndash;&gt;-->
      <!--            &lt;!&ndash;                  canvasMute ? "Разблокировать доску" : "Заблокировать доску"&ndash;&gt;-->
      <!--            &lt;!&ndash;                }}&ndash;&gt;-->
      <!--            &lt;!&ndash;              </v-btn>&ndash;&gt;-->
      <!--            &lt;!&ndash;            </div>&ndash;&gt;-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
  </v-container>
</template>

<script>
import Konva from "konva";
import { mapActions, mapGetters, mapMutations } from "vuex";
import axios from "axios";

export default {
  name: "CanvasDesk",
  props: {
    JSONcanvas: {
      type: [Object],
    },
    muteCanvas: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      imgToSend: null,
      hasImage: false,
      brushSize: 10,
      brushColor: "#000000",
      textValue: "",
      activeTab: 1,
      // actions: [
      //   {
      //     id: 1,
      //     img: require("@/assets/images/canvas/brush.svg"),
      //     type: "brush",
      //   },
      //   {
      //     id: 2,
      //     img: require("@/assets/images/canvas/clear.svg"),
      //     type: "clear",
      //   },
      //   {
      //     id: 3,
      //     img: require("@/assets/images/canvas/text.svg"),
      //     type: "text",
      //   },
      //   {
      //     id: 4,
      //     img: require("@/assets/images/canvas/rect.svg"),
      //     type: "rect",
      //   },
      //   {
      //     id: 5,
      //     img: require("@/assets/images/canvas/circle.svg"),
      //     type: "circle",
      //   },
      //   {
      //     id: 6,
      //     img: require("@/assets/images/canvas/img.svg"),
      //     type: "img",
      //   },
      // ],
      canvasMute: false,
    };
  },
  // methods: {
  //   toggleMute() {
  //     this.canvasMute = !this.canvasMute;
  //     this.$emit("toggleMute", this.canvasMute);
  //   },
  //   async sendImg() {
  //     // this.lessonData.user.id
  //     try {
  //       const formData = new FormData();
  //       formData.append("userId", this.lessonData.user.id);
  //       formData.append("image", this.imgToSend);
  //       const response = await axios({
  //         baseURL: this.URL + "/api/t/board_image",
  //         headers: {
  //           Accept: "application/json",
  //           "Content-Type": "multipart/form-data",
  //           Authorization: `Bearer ${this.token}`,
  //         },
  //         method: "POST",
  //         data: formData,
  //       });
  //       return response.data;
  //     } catch (e) {
  //       this.showAlert({
  //         show: true,
  //         type: "error",
  //         msg: e.response.data.message,
  //       });
  //     } finally {
  //       this.imgToSend = null;
  //     }
  //   },
  //   ...mapMutations(["showAlert"]),
  // },
  computed: {
    ...mapGetters(["lessonData", "URL", "token"]),
  },
  mounted() {
    function createRect(fill = "transparent", stroke = "black") {
      const rect = new Konva.Rect({
        x: 10,
        y: 10,
        width: 100,
        height: 50,
        fill: fill,
        stroke: stroke,
        strokeWidth: 2,
        draggable: true,
      });
      layer.add(rect);
      rect.on("dblclick", (e) => {
        // скидываем резайсер и удаляем элемент
        tr.nodes([]);
        rect.destroy();
        sendJSON();
      });
      sendJSON();
    }

    function createCircle(fill = "transparent", stroke = "black") {
      const circle = new Konva.Circle({
        x: 30,
        y: 30,
        width: 100,
        height: 50,
        fill: fill,
        stroke: stroke,
        strokeWidth: 2,
        draggable: true,
      });
      layer.add(circle);
      circle.on("dblclick", (e) => {
        // скидываем резайсер и удаляем элемент
        tr.nodes([]);
        circle.destroy();
        sendJSON();
      });
      sendJSON();
    }

    function createText(text) {
      console.log(vm.brushColor);
      let textNode = new Konva.Text({
        text: text,
        x: 50,
        y: 50,
        fontSize: 20,
        draggable: true,
        fill: vm.brushColor,
      });
      textNode.on("dblclick", (e) => {
        // скидываем резайсер и удаляем элемент
        tr.nodes([]);
        textNode.destroy();
        sendJSON();
      });
      layer.add(textNode);
      sendJSON();
    }

    function createLine(pos, currentSize) {
      const mode = vm.activeTab === 1 ? "brush" : "eraser";
      lastLine = new Konva.Line({
        stroke: vm.brushColor,
        strokeWidth: currentSize,
        globalCompositeOperation:
          mode === "brush" ? "source-over" : "destination-out",
        // round cap for smoother lines
        lineCap: "round",
        lineJoin: "round",
        // add point twice, so we have some drawings even on a simple click
        points: [pos.x, pos.y, pos.x, pos.y],
        draggable: mode === "brush",
      });
      lastLine.on("dblclick", (e) => {
        // скидываем резайсер и удаляем элемент
        tr.nodes([]);
        lastLine.destroy();
        sendJSON();
      });
      layer.add(lastLine);
    }

    const vm = this;
    const canvasContainer = this.$refs.canvas;
    let currentShape;

    // обработка создания изображения
    const addImg = this.$refs.image;
    const addImgBtn = this.$refs.addImage.$el;
    addImgBtn?.addEventListener("click", (e) => {
      addImg.click();
    });
    addImg?.addEventListener("change", (e) => {
      const URL = window.webkitURL || window.URL;
      const url = URL.createObjectURL(e.target.files[0]);
      const img = new Image();
      img.src = url;
      img.onload = async function () {
        vm.imgToSend = e.target.files[0];
        const response = await vm.sendImg();
        vm.hasImage = true;
        const img_width = img.width;
        const img_height = img.height;

        // calculate dimensions to get max 300px
        const maxWidth = canvasContainer.clientWidth;
        const maxHeight = canvasContainer.clientHeight;
        const ratio =
          img_width > img_height
            ? img_width / maxWidth
            : img_height / maxHeight;

        const imgNode = new Konva.Image({
          image: img,
          x: (canvasContainer.clientWidth - img_width / ratio) / 2,
          y: (canvasContainer.clientHeight - img_height / ratio) / 2,
          width: img_width / ratio,
          height: img_height / ratio,
          draggable: false,
          rotation: 0,
          id: "img",
          name: vm.URL + response.image,
          listening: false,
        });
        console.log(img_width / ratio);
        console.log(img_height / ratio);
        imgNode.on("dblclick", (e) => {
          // скидываем резайсер и удаляем элемент
          tr.nodes([]);
          imgNode.destroy();
        });
        layer.add(imgNode);
        sendJSON();
        // vm.$emit('sendImg', response)
      };
    });

    // Обработка создания квадрата и круга
    const addRect = this.$refs.rect.$el;
    addRect.addEventListener("click", () => {
      const strokeColor = this.$refs.strokeColor.value;
      const fillColor = this.$refs.fillColor.value;
      const transparent = this.$refs.fillTransparent.checked;
      vm.activeTab === 4
        ? createRect(transparent ? "transparent" : fillColor, strokeColor)
        : createCircle(transparent ? "transparent" : fillColor, strokeColor);
    });

    // Обработка создания текста
    const addText = this.$refs.textAdd.$el;
    addText.addEventListener("click", () => {
      createText(vm.textValue);
      vm.textValue = "";
    });

    const clearLayer = this.$refs.clear.$el;
    clearLayer.addEventListener("click", () => {
      layer.removeChildren();
      sendJSON();
      vm.hasImage = false;
    });
    // first we need to create a stage
    const stage = new Konva.Stage({
      container: canvasContainer, // id of container <div>
      width: canvasContainer.clientWidth,
      height: canvasContainer.clientHeight,
    });

    // навешиваем резайсер при клике на элемент
    stage.on("click", function (e) {
      currentShape = e.target;
      if (currentShape === stage) {
        tr.nodes([]);
      } else {
        tr.nodes([currentShape]);
      }
      layer.draw();
    });
    // then create layer
    const layer = new Konva.Layer();
    // add the layer to the stage
    stage.add(layer);
    // создаем резайер
    let tr = new Konva.Transformer({
      keepRatio: false,
      rotationSnaps: [0, 90, 180, 270],
    });

    layer.add(tr);
    // рисование
    let isPaint = false;
    let lastLine;
    stage.on("mousedown touchstart", function (e) {
      console.log(e.target);
      if (!e.target.content) {
        return;
      }
      const currentSize = Number(vm.brushSize);
      isPaint = true;
      let pos = stage.getPointerPosition();
      createLine(pos, currentSize);
    });
    stage.on("mouseup touchend", function () {
      isPaint = false;
      sendJSON();
    });
    // and core function - drawing
    stage.on("mousemove touchmove", function (e) {
      if (!isPaint) {
        return;
      }

      // prevent scrolling on touch devices
      e.evt.preventDefault();

      const pos = stage.getPointerPosition();
      let newPoints = lastLine.points().concat([pos.x, pos.y]);
      lastLine.points(newPoints);
    });

    // draw the image
    layer.draw();

    function sendJSON() {
      vm.$emit("JSON", stage.toJSON());
    }
  },
  watch: {
    JSONcanvas(old, newval) {
      if (this.JSONcanvas) {
        this.stage = Konva.Node.create(this.JSONcanvas, "canvas");
        const image = this.stage.findOne("#img");
        if (image) {
          const imageObject = new Image();
          imageObject.onload = () => {
            this.stage.findOne("#img").image(imageObject);
          };
          imageObject.src = image.name();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#canvas {
  pointer-events: none;
}
.canvas-wrapper {
  padding: 20px 0;
  display: flex;
  gap: 20px;
}

.canvas {
  flex: 0 0 1045px;
  width: 1045px;
  height: 854px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.canvas-actions {
  flex: 0 0 294px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  padding: 34px 24px;
  display: flex;
  flex-direction: column;

  span {
    display: block;
    margin: 0 0 8px;
    font-size: 18px;
    color: #949494;
  }
}

.action-group {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;

  input:not([type="color"]) {
    padding: 5px 10px;
    border: 1px solid;
    width: 100%;
  }
}

.actions {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  &__btn {
    margin-top: auto;
  }

  // .actions__tabs
  &__tabs {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
    margin: 0 0 42px;
  }

  &__tab {
    height: 61px;
    background: linear-gradient(154.41deg, #b87dea 6.64%, #7ec1ff 87.72%);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.4;
    transition: opacity 0.2s ease;

    &.active {
      opacity: 1;
    }
  }

  &__content {
  }
}

.action {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 14px;
  gap: 8px;

  span {
    flex: 0 0 100%;
    margin: 0;
    line-height: 1;
  }

  textarea {
    padding: 10px;
    height: 96px;
    border: 1px solid rgba(83, 83, 83, 0.5);
    width: 100%;
    font-size: 16px;
    line-height: 20px;
    color: #000;
    resize: none;
  }
}

.size {
  border: 1px solid rgba(83, 83, 83, 0.5);
  max-width: 40px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  &-plus {
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    color: #fff;
    background: #b87dea;
  }

  &-minus {
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    color: #fff;
    background: #b87dea;
  }
}

input[type="color"] {
  width: 80px;
  height: 42px;
}

.action-item {
  flex: 1 1 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.file-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  opacity: 0;
}
@media (max-width: 1500px) {
  .canvas-wrapper {
    flex-direction: column;
    padding: 240px 0 0;
  }
  .canvas {
    flex: 1 1 auto;
    width: 100%;
    height: 600px;
  }
  .actions__tabs {
    width: 404px;
    grid-template-columns: repeat(5, 1fr);
    margin: 0 0 12px;
  }
}
</style>
