<template>
<!--  <div class="page">-->
<!--    <v-container>-->
<!--      <h2 class=" title title-md">Расписание занятий</h2>-->
<!--      <v-card width="98%" class="mx-auto">-->
<!--        <v-card-title>-->
<!--          <v-text-field-->
<!--            v-model="search"-->
<!--            append-icon="mdi-magnify"-->
<!--            label="Поиск"-->
<!--            single-line-->
<!--            hide-details-->
<!--          ></v-text-field>-->
<!--        </v-card-title>-->
<!--        <v-data-table-->
<!--          locale="ru-RU"-->
<!--          :headers="headers"-->
<!--          :items="sortingLessons"-->
<!--          :search="search"-->
<!--          disable-sort-->
<!--        >-->
<!--          <template v-slot:item.btn="{item}">-->
<!--            <a class="table-link disabled" v-if="item.end">Урок завершен</a>-->
<!--            <a class="table-link" :class="{disabled: !item.ready}"-->
<!--               @click="goLesson(item.btn, item.teacher)" v-else>{{-->
<!--                item.ready ? 'Начать урок' : 'Доступно позже'-->
<!--              }}</a>-->
<!--          </template>-->
<!--        </v-data-table>-->
<!--      </v-card>-->
<!--    </v-container>-->

<!--  </div>-->
  <div class="empty">
    в разработке

  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import axios from "axios";
import {format, getTime} from "date-fns";

export default {
  name: "LessonsPage",
  data() {
    return {
      search: '',
      headers: [
        {text: 'Дата', value: 'date'},
        {text: 'Время', value: 'time'},
        {text: 'Преподаватель', value: 'teacher'},
        {text: 'Ссылка', value: 'btn', sortable: false,}
      ],
      lessons: [],
      headersHistory: [
        {text: 'Дата', value: 'date'},
        {text: 'Вид операции', value: 'type'},
        {text: '', value: 'btn', sortable: false,}
      ],
      showPaid: null,
      successPaid: null,
      paidInfo: {
        status: null,
        paid: null,
      }
    }
  },
  methods: {
    async fetchLessons() {
      try {
        const response = await axios(`${this.API_URL}/lessons?pagination=false`, {
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${this.token}`
          }
        })

        const lessonsFromApi = response.data;
        this.lessons = lessonsFromApi.map(function (item) {
          let ready = false;
          let end = false;
          if (item.userAuth) {
            ready = true
          }
          if (item.dateTimeFinishedAt) {
            end = true;
          }
          return {
            dateTimeAt: item.dateTimeAt,
            teacher: item.teacher.fullName,
            btn: {
              peerIds: item.peerIds,
              userAuth: item.userAuth,
              id: item.id
            },
            date: format(new Date(item.dateTimeAt), 'd.M.YYY'),
            time: `${format(new Date(item.dateTimeAt), 'H:mm')} (${item.duration} мин)`,
            ready: ready,
            end: end,

          }
        })
      } catch (e) {
        console.log()
      } finally {
      }

    },
    completedLessons(balance) {
      return 100 - (balance.availableLessons / (balance.bookedLessons + balance.availableLessons) * 100)
    },
    async redirectFromYookassa() {
      this.setIsLoading({boolean: true})
      try {
        if (this.idempotenceKey) {
          const response = await axios(`${this.API_URL}/payments/${this.idempotenceKey}`, {
            headers: {
              'Accept': 'application/json',
              'Authorization': `Bearer ${this.token}`
            }
          });
          const isPaid = response.data.paid;
          this.showPaid = true;
          if (isPaid) {
            this.successPaid = true;
          } else {
            this.successPaid = false;
          }
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.setIsLoading({boolean: false})
      }

    },
    refresh() {
      setInterval(() => {
        this.fetchLessons()
      }, 60000)
    },
    goLesson(data, teacher) {
      this.$router.push({name: 'videocall', params: {data: data, teacher: teacher}})
    },

    ...mapActions(['setIsLoading']),
  },

  computed: {
    ...mapGetters(['profileTab', 'student', 'token', 'API_URL', 'idempotenceKey']),
    sortingLessons() {
      return this.lessons.sort((a, b) => getTime(new Date(b.dateTimeAt)) - getTime(new Date(a.dateTimeAt)))
    }
  },
  mounted() {
  },

}
</script>

<style lang="scss" scoped>
.page {
  padding-bottom: 90px;
}

.card {
  padding: 40px !important;
  border-radius: 15px !important;

  &__title {
    font-family: 'Nunito', 'sans-serif';
    font-weight: 900;
    font-size: 18px;
    line-height: 140%;
    text-transform: uppercase;
    color: #414141;
    text-align: center;
    justify-content: center;
    margin-bottom: 28px;
  }

  .totals {
    display: flex;
  }

  .total {
    font-weight: 400;
    font-size: 14px;
    color: #9C9C9C;
    display: flex;
    align-items: center;
    gap: 6px;

    span {
      color: #4C4C4C;
    }
  }
}

.table-link {
  display: flex;
  font-size: 14px;
  color: #fff;
  background: #B87DEA;
  border-radius: 40px;
  height: 32px;
  align-items: center;
  justify-content: center;
  padding: 0 5px;

  &.disabled {
    background: gray;
    pointer-events: none;
    cursor: inherit;
  }
}

.counter {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.count {
  font-size: 24px;

  span {
    font-size: 28px;
    font-weight: 700;
  }
}

.btns {
  display: flex;
  gap: 24px;
}

.popup {
  &__body {

    padding: 0 24px 24px;

    h3 {
      font-size: 24px;
      font-weight: 700;
      text-align: center;
      margin-bottom: 12px;
    }

    .text {
      font-size: 16px;
      text-align: center;
    }
  }
}

@media (max-width: 1200px) {
  .count {
    font-size: 18px;

    span {
      font-size: 20px;
    }
  }
}

@media (max-width: 992px) {
  .count {
    font-size: 14px;

    span {
      font-size: 16px;
    }
  }
  .btns {
    flex-direction: column;
  }
}

@media (max-width: 540px) {
  .card {
    padding: 24px !important;

    &__title {
      font-size: 14px;
      margin-bottom: 12px;
    }

    .totals {
      flex-direction: column;
      padding: 0;
      margin: 24px 0;

    }

    .total {
      justify-content: space-between;
    }
  }

  .popup {
    &__body {

      padding: 0 12px 12px;

      h3 {
        font-size: 18px;
      }

      .text {
        font-size: 14px;
      }
    }
  }

}

.empty {
  margin: 140px auto 0;
  text-align: center;
}
</style>
