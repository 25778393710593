<template>
  <v-app-bar
    fixed
    color="white"
    elevate-on-scroll
    hide-on-scroll
    class="header"
    :height="72"
    app
  >

    <v-container class="header__container">
      <v-toolbar-title>
        <div class="header__logo">
          <router-link to="/">
            <img src="@/assets/svg/header-logo.svg" alt="Логотип">
          </router-link>
        </div>
      </v-toolbar-title>

      <div class="header__mobile">
        <v-app-bar-nav-icon @click.stop="showProfile = !showProfile" color="white" icon>
          <v-img v-if="userInfo.photo" class="header__user" :src="URL+userInfo.photo"/>
          <v-img v-else class="header__user" src="@/assets/images/user.jpg"/>
        </v-app-bar-nav-icon>
      </div>

      <v-navigation-drawer
        v-model="showProfile"
        absolute
        app
        width="330"
        temporary
        class="profile-menu"
      >
        <div @click="$router.push({name: 'profile'})" class="profile">
          <div class="profile__img">
            <v-img v-if="userInfo.photo" :src="URL+userInfo.photo"/>
            <v-img v-else src="@/assets/images/user.jpg"/>
          </div>
          <div v-if="userInfo.photo" class="profile__name">
            {{ userInfo.fullName }}<br>
            {{ userInfo.email }}
            <span>Учитель</span>
          </div>
        </div>

        <div class="profile-links">
          <!--          <RouterLink to="/lessons" active-class="v-tab&#45;&#45;active" class="profile-tab">-->
          <!--            <v-icon color="#1F1F1F">-->
          <!--              mdi-book-account-outline-->
          <!--            </v-icon>-->
          <!--            <span>Мои ученики</span>-->
          <!--          </RouterLink>-->
          <RouterLink to="/profile" active-class="v-tab--active" class="profile-tab">
            <v-icon color="#1F1F1F">
              mdi-school-outline
            </v-icon>
            <span>Мои занятия</span>
          </RouterLink>
          <!--          <RouterLink to="/about" active-class="v-tab&#45;&#45;active" class="profile-tab">-->
          <!--            <v-icon color="#1F1F1F">-->
          <!--              mdi-account-->
          <!--            </v-icon>-->
          <!--            <span>Мой профиль</span>-->
          <!--          </RouterLink>-->
        </div>
        <div @click="logout" class="logout">
          <v-icon>
            mdi-logout
          </v-icon>
          Выйти
        </div>
      </v-navigation-drawer>
    </v-container>
  </v-app-bar>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "ProfileHeader",
  data() {
    return {
      showProfile: false,
    }
  },
  methods: {
    ...mapActions(['setIsAuth', 'logout']),
  },
  computed: {
    ...mapGetters(['userInfo', 'URL']),
  },

}
</script>

<style lang="scss" scoped>
.header {
  &__container {
    justify-content: space-between;
    gap: 32px;
  }
}

.profile-menu {
  padding: 70px 40px;
}

.profile-tab {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #1f1f1f !important;
  width: 100%;
  gap: 12px;
  flex: 1 1 auto;
  font-weight: 500;
  justify-content: flex-start;
  fill: #1f1f1f;
  height: 48px;
  padding: 0 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }

  &.v-tab--active {
    fill: #d944ff;

    &:before {
      bottom: 0;
      content: "";
      left: 0;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;
      transition: .3s cubic-bezier(.25, .8, .5, 1);
      background: #d944ff;
      height: 48px;
      width: 2px;
    }
  }

  img {
    flex: 0 0 24px;
    height: 24px;
  }
}

.logout {
  display: flex;
  align-items: flex-end;
  gap: 8px;
  font-size: 18px;
  color: #9C9C9C;
  margin-top: 190px;
  cursor: pointer;
  font-weight: 500;
  line-height: 24px;
  padding: 12px;

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
}

.profile {
  margin-bottom: 50px;
  display: flex;
  gap: 8px;

  &__name {
    margin: 4px 0;
    font-family: 'Nunito';
    font-weight: 900;
    font-size: 18px;
    display: flex;
    flex-direction: column;

    span {
      font-family: 'Montserrat';
      font-weight: 400;
      font-size: 12px;
      color: #9C9C9C;
    }
  }

  &__img {
    width: 61px;
    height: 61px;
    border-radius: 50%;
    overflow: hidden;
  }
}

.profile-links {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

@media (max-width: 768px) {
  .profile-menu {
    padding: 40px 24px;
  }
}
</style>
