<template>
  <div class='content'>
    <v-container>
      <div class='card'>
        <div class='card__header'>
          <v-row>
            <v-col cols='12' md='6'>
              <div class='card__title'>Расписание занятий</div>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols='2'>
              <div @click='show = true' class='filter'>
                <v-icon color='#9C9C9C'> mdi-filter-outline</v-icon>
                <span>Фильтр</span>
              </div>
            </v-col>
            <v-col cols='2'>
              <div @click='reverseLessons' class='filter'>
                <v-icon color='#9C9C9C'>
                  {{ reverse ? 'mdi-sort-descending' : 'mdi-sort-ascending' }}
                </v-icon>
                <span>По дате</span>
              </div>
            </v-col>
          </v-row>
        </div>
        <div class='table'>
          <div class='table__header'>
            <v-row>
              <v-col cols='2'>
                <div class='table__item'>
                  <v-icon> mdi-calendar-month-outline</v-icon>
                  <span>Дата</span>
                </div>
              </v-col>
              <v-col cols='2'>
                <div class='table__item'>
                  <v-icon> mdi-clock-outline</v-icon>
                  <span>Время</span>
                </div>
              </v-col>
              <v-col cols='3'>
                <div class='table__item'>
                  <span>Ученик</span>
                </div>
              </v-col>
              <v-col cols='3'>
                <div class='table__item'>
                  <span>Тариф</span>
                </div>
              </v-col>
            </v-row>
          </div>
          <div
              v-if='sortedUpcomingLessons.length || sortedFinishedLessons.length'
              class='table__content'
          >
            <transition-group name='slide-fade'>
              <div
                  v-for='(lesson, i) in checkAvailableLessons'
                  :key='lesson.id'
                  :class="['table__row', { active: i === 0 }]"
              >
                <v-row class='my-0'>
                  <v-col cols='2'>
                    <div class='table__item'>
                      <span>{{ lesson.date }}</span>
                    </div>
                  </v-col>
                  <v-col cols='2'>
                    <div class='table__item'>
                      <span>{{ lesson.time }}</span>
                    </div>
                  </v-col>
                  <v-col cols='3'>
                    <div class='table__item'>
                      <span>
                        {{ lesson.user.firstName }}
                        {{ lesson.user.lastName }}
                      </span>
                    </div>
                  </v-col>
                  <v-col cols='2'>
                    <div class='table__item'>
                      <span>
                        «{{
                          lesson.type === 'standard' ? 'Стандарт' : 'Премиум'
                        }}», {{ lesson.duration }} мин.
                      </span>
                    </div>
                  </v-col>
                  <v-col cols='3'>
                    <v-row>
                      <v-col cols='6'>
                        <v-btn
                            @click='startLesson(lesson)'
                            color='primary'
                            rounded
                            width='100%'
                            :disabled='!lesson.ready'
                        >
                          {{ lesson.ready ? ' Начать занятие' : 'Доступно позже' }}
                        </v-btn>
                      </v-col>
                      <v-col v-if='lesson.dateTimeStartedAt' cols='6'>
                        <v-btn
                            @click='endLesson(lesson)'
                            color='error'
                            rounded
                            width='100%'
                        >
                          Завершить
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
              <div
                  v-for='(lesson, i) in sortedFinishedLessons'
                  :key='lesson.id'
                  :class="['table__row']"
              >
                <v-row class='my-0'>
                  <v-col cols='2'>
                    <div class='table__item'>
                      <span>{{ lesson.date }}</span>
                    </div>
                  </v-col>
                  <v-col cols='2'>
                    <div class='table__item'>
                      <span>{{ lesson.time }}</span>
                    </div>
                  </v-col>
                  <v-col cols='3'>
                    <div class='table__item'>
                      <span>
                        {{ lesson.user.firstName }}
                        {{ lesson.user.lastName }}
                      </span>
                    </div>
                  </v-col>
                  <v-col cols='2'>
                    <div class='table__item'>
                      <span>
                        «{{
                          lesson.type === 'standard' ? 'Стандарт' : 'Премиум'
                        }}», {{ lesson.duration }} мин.
                      </span>
                    </div>
                  </v-col>
                  <v-col cols='3'>
                    <v-row>
                      <v-col cols='12'>
                        <v-btn disabled color='primary' rounded width='100%'>
                          Завершено
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
            </transition-group>
          </div>
          <div v-else class='empty'>- Уроков нет -</div>
        </div>
      </div>
    </v-container>
    <v-dialog v-model='show' max-width='600' content-class='no-scrollbar'>
      <TeacherFilters @filter='filter' @close='show = false'/>
    </v-dialog>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex'
import TeacherFilters from '@/components/popups/TeacherFiltres.vue'
import {getUnixTime, parse} from 'date-fns'
import axios from 'axios'
import store from '@/store'

export default {
  name: 'ProfilePage',
  components: {TeacherFilters},
  data() {
    return {
      reverse: false,
      show: false,
      filters: null,
      interval: null
    }
  },
  methods: {
    filter(filter) {
      this.filters = filter
    },
    async patchLesson(lesson) {
      const URL = `${this.URL}/api/t/lesson/${lesson.id}/start`
      try {
        const response = await axios({
          baseURL: URL,
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${this.token}`,
            'Content-Type': 'application/merge-patch+json'
          },
          method: 'PATCH',
          data: {}
        })
      } catch (e) {
        store.commit('showAlert', {
          show: true,
          type: 'error',
          msg: e.message
        })
        throw new Error(e)
      }

    },
    async startLesson(lesson) {
      window.open(lesson.link, '_blank')
      await this.patchLesson(lesson)
      await this.fetchLessons()
      // this.createLessonData(lesson);
      // this.$router.push({ name: "videocall" });
    },
    async endLesson(lesson) {
      const URL = `${this.URL}/api/t/lesson/${lesson.id}/finish`
      try {
        const response = await axios({
          baseURL: URL,
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${this.token}`,
            'Content-Type': 'application/merge-patch+json'
          },
          method: 'PATCH',
          data: {}
        })
        store.commit('showAlert', {
          show: true,
          type: 'info',
          msg: 'Занятие завершено, спасибо за урок!'
        })
      } catch (e) {
        store.commit('showAlert', {
          show: true,
          type: 'error',
          msg: e.message
        })
        throw new Error(e)
      }
      await this.fetchLessons()
    },

    reverseLessons() {
      this.reverse = !this.reverse
    },
    refresh() {
      this.interval = setInterval(() => {
        this.fetchLessons()
      }, 60000)
    },
    ...mapMutations(['createLessonData']),
    ...mapActions(['fetchLessons'])
  },
  computed: {
    sortedUpcomingLessons() {
      let array = this.upcomingLessons
      if (this.reverse) {
        array = array.sort((a, b) => a.unixTime - b.unixTime)
      } else {
        array = array.sort((a, b) => b.unixTime - a.unixTime)
      }
      if (this.filters) {
        const {name, dateStart, dateFinish, type} = this.filters
        if (name) {
          // сравнение вхождения имени в поле имени ученика
          array = array.filter((item) =>
              item.user.firstName.toLowerCase().includes(name.toLowerCase())
          )
        }
        if (dateStart) {
          // приводим время к одному значению, с учтом часового пояса и сравниваем
          const unix = getUnixTime(
              parse(`${dateStart}:00.00`, 'dd.MM.yyyy:HH.mm', new Date())
          )
          const timezone = new Date().getTimezoneOffset() * 60
          const newTime = unix + timezone
          array = array.filter((item) => {
            const itemUnix = getUnixTime(
                parse(`${item.date}:00.00`, 'dd.MM.yyyy:HH.mm', new Date())
            )
            return itemUnix >= newTime
          })
        }
        if (dateFinish) {
          const unix = getUnixTime(
              parse(`${dateFinish}:00.00`, 'dd.MM.yyyy:HH.mm', new Date())
          )
          const timezone = new Date().getTimezoneOffset() * 60
          const newTime = unix + timezone + 86399
          array = array.filter((item) => {
            const itemUnix = getUnixTime(
                parse(`${item.date}:00.00`, 'dd.MM.yyyy:HH.mm', new Date())
            )
            return itemUnix <= newTime
          })
        }
        if (type.length > 0) {
          array = array.filter((item) => type.includes(item.typeDuration))
        }
      }
      return array
    },
    sortedFinishedLessons() {
      let array = this.finishedLessons
      if (this.reverse) {
        array = array.sort((a, b) => b.unixTime - a.unixTime)
      } else {
        array = array.sort((a, b) => a.unixTime - b.unixTime)
      }
      if (this.filters) {
        const {name, dateStart, dateFinish, type} = this.filters
        if (name) {
          array = array.filter((item) =>
              item.user.firstName.toLowerCase().includes(name.toLowerCase())
          )
        }
        if (dateStart) {
          const unix = getUnixTime(
              parse(`${dateStart}:00.00`, 'dd.MM.yyyy:HH.mm', new Date())
          )
          const timezone = new Date().getTimezoneOffset() * 60
          const newTime = unix + timezone
          array = array.filter((item) => {
            const itemUnix = getUnixTime(
                parse(`${item.date}:00.00`, 'dd.MM.yyyy:HH.mm', new Date())
            )
            return itemUnix >= newTime
          })
        }
        if (dateFinish) {
          const unix = getUnixTime(
              parse(`${dateFinish}:00.00`, 'dd.MM.yyyy:HH.mm', new Date())
          )
          const timezone = new Date().getTimezoneOffset() * 60
          const newTime = unix + timezone + 86399
          array = array.filter((item) => {
            const itemUnix = getUnixTime(
                parse(`${item.date}:00.00`, 'dd.MM.yyyy:HH.mm', new Date())
            )
            return itemUnix <= newTime
          })
        }
        if (type.length > 0) {
          array = array.filter((item) => type.includes(item.typeDuration))
        }
      }
      return array
    },
    checkAvailableLessons() {
      return this.sortedUpcomingLessons.map(lesson => {
        const localUnix = getUnixTime(new Date())
        const {unixTime} = lesson
        const timeDifference = localUnix - unixTime
        if(lesson.duration >= 55) {
          if (timeDifference <= 1800 && timeDifference >= -1800) {
            return {...lesson, ready: true}
          } else {
            return {...lesson, ready: false}
          }
        } else {
          if (timeDifference <= 900 && timeDifference >= -900) {
            return {...lesson, ready: true}
          } else {
            return {...lesson, ready: false}
          }
        }

      })
    },
    ...mapGetters(['profileTab', 'upcomingLessons', 'finishedLessons', 'URL', 'token'])
  },

  async mounted() {
    await this.fetchLessons()
  },
  beforeDestroy() {
    clearInterval(this.interval)
  }
}
</script>

<style lang='scss' scoped>
.content {
  background: #f7f7f7;
  padding: 50px 40px;
  height: 100%;
}

.card {
  padding: 32px 32px;
  background: #fff;
  border: 1px solid #f2f2f2;
  border-radius: 15px;
  // .card__header
  &__header {
    margin: 0 0 32px;
  }

  // .card__title
  &__title {
    font-family: "Nunito", serif;
    font-weight: 900;
    font-size: 32px;
  }
}

.filter {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  height: 100%;
  cursor: pointer;
  background: #f7f7f7;
  border-radius: 4px;
  transition: all 0.3s;

  &:hover {
    box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.3);
  }
}

.table {
  // .table__header
  &__header {
    background: rgba(217, 217, 217, 0.2);
    margin: 0 0 24px;
    padding: 0 12px;

    .table__item {
      font-weight: 600;
    }
  }

  // .table__item
  &__item {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    height: 100%;
  }

  // .table__row
  &__row {
    padding: 0 12px;

    &:nth-child(odd) {
      background: #f7f7f7;
    }

    &.active {
      background: #faebfe;
    }
  }

  // .table__content
  &__content {
  }
}

.empty {
  margin: 32px auto 0;
  text-align: center;
}
</style>
